import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import io from "socket.io-client";

const root = ReactDOM.createRoot(document.getElementById("root"));
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const socketInstance = io(SOCKET_URL, {
  transports: ["websocket"],
  autoConnect: false,
  reconnect: false,
});

if (!process.env.REACT_APP_EXPESS_URL || !process.env.REACT_APP_SOCKET_URL) {
  throw new Error("No .env configuration for api found!");
}

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
