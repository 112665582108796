import React, { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import { PlayerContextProvider } from "./PlayerContext";

const GameReport = lazy(() => import("./GameReport/index.js"));
const Game = lazy(() => import("./Game.js"));
const Home = lazy(() => import("./Home.js"));

function App() {
  return (
    <BrowserRouter>
      <div className="app__container">
        <PlayerContextProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/game/:gameId" element={<Game />} />
            <Route path="/game/:gameId/results" element={<GameReport />} />
          </Routes>
        </PlayerContextProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
