import React, { useState, createContext, useContext } from "react";

const PlayerContext = createContext(null);

export const PlayerContextProvider = ({ children }) => {
  const [playerColor, setPlayerColor] = useState(null);
  const [playerName, setPlayerName] = useState(null);

  const provider = {
    playerColor,
    playerName,
    setPlayerColor,
    setPlayerName,
  };

  return (
    <PlayerContext.Provider value={provider}>{children}</PlayerContext.Provider>
  );
};

export const usePlayerContext = () => {
  return useContext(PlayerContext);
};
